<template>
    <div>
        <div>404</div>
        <div>请使用微信浏览器</div>
    </div>
</template>

<script>
export default {
};
</script>

<style scoped>

</style>
